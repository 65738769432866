@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
.card{
    padding-left: 10px;
    padding-right: 10px;
    min-width: 410px;
    scroll-snap-align: start;
    .card-header{
        min-height: 25px;
        max-height: 25px;
        background-color: #0092FF;

        .circle{
            position: relative;
            width: 15px;
            height: 15px;
            background-color: white;
            border-radius: 100%;
            display: inline-block;
            margin-left: 10px;
            margin-top: 5px;
        }
    }
    .card-container{
        height: 100%;
        min-height: 60px;
        background-color: var(--panel);
        overflow-y: scroll;

        .header{
            display: flex;
            padding: 10px;

            img{
                width: 100px;
                height: 100px;
                margin-top: auto;
                margin-bottom: auto;
            }

            .content{
                padding-left: 10px;
                h1{
                    font-family: "SweetPurple";
                    color: var(--color);
                    font-size: 35px;
                }
                p{
                    font-family: 'CherishToday';
                    color: var(--color);
                    font-size: 20px;
                }
            }
        }

        .description{
            padding: 15px;
            padding-top: 0;
            font-family: 'Roboto', sans-serif;
            font-size: 20px;
            color: var(--color);
            margin-bottom: 10px;
        }
    }
}

@media screen and (max-width: 960px) { 
    .experience{
        .work-experience{
            .left-slider{
                padding: 0;
            }
            .right-slider{
                padding: 0;
            }

            .experience-cards{
                max-width: 300px;
                height: calc(100vh - 300px);

                .experience-container{
                    height: 100%;
                    padding-bottom: 20px;
                }
            }
        }
    }
    .card{
        min-width: 275px;

        .card-container{
            .header{
                display: block;
                img{
                    display: none;
                }

                .content{
                    h1{
                        font-size: 30px;
                    }
                }
            }
            .description{
                p{
                    font-size: 15px;
                }
            }
        }
    }
}