@import '../shared/DefaultColours';
.switch {
    position: relative;
    display: inline-block;
    width: 85px;
    height: 35px;
    margin-top: auto;
    margin-bottom: auto;

    input{
        visibility: hidden;
    }
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid var(--LightText);
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 31px;
    width: 31px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border: 2px solid var(--LightText);
    top: -2px;
    left: -2px;
  }
  
  input:checked + .slider {
    background-color: var(--DarkSwitch);
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px  var(--DarkSwitch);
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }