@import '../shared/DefaultColours';

.navbar{
    z-index: 999;
    display: flex;
    position: fixed;
    background-color: var(--background);
    width: 100%;
    height: 85px;
	-webkit-box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.56); 
            box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.56);

    .logo .navbar-logo{
        font-family: "Rattman";
        color: var(--color);
        font-size: 70px;
        margin-left: 15px;
        font-weight: 400;
        text-decoration: none;
    }

    .menu-icon{
        visibility: hidden;
    }

    .is-active{
        border-bottom: 4px solid var(--color);
        border-radius: 0.25rem;
      }

    .menu{
        margin-left: auto;
        margin-right: 15px;
        margin-top: auto;
        margin-bottom: auto;
        display: flex;

        ul{
            display: flex;
            list-style-type:none;
            padding-right: 15px;
            li{
                padding-left: 20px;
                margin-top: auto;
                margin-bottom: auto;

                a{
                    text-decoration: none;
                    font-family: 'CherishToday';
                    font-size: 40px;
                    color: var(--color)
                }
            }
        }

        li:hover{
            a{
                color: var(--hover-color);
            }
        }
    }
}

@media screen and (max-width: 960px) {
    .menu{
        .switch{
            visibility: hidden;
        }
        ul{
            li{
                padding-left: 0 !important;
                margin-top: 30px !important;
                margin-bottom: 0 !important;
            }
        }
    }

    .navbar{
        .navbar-logo{
            font-size: 60px !important;
            margin-top: 10px;
        }
    }

    .menu-icon{
        visibility: visible !important;
    }

    .NavbarItems {	
        position: relative;	
      }
    
      .nav-menu {	
        display: flex;	
        flex-direction: column;	
        width: 100%;	
        height: calc(100vh - 60px);	
        position: absolute;	
        top: 60px;	
        left: -100%;	
        opacity: 1;	
        transition: all 0.5s ease;	
        font-family: 'CherishToday';
        margin-top: 20px;
      }	
      
      .nav-menu.active {	
        background:var(--background);
        left: 0px;	
        transition: all 0.5s ease;	
        z-index: 1;	
        padding: 0;
      }	
    
      .nav-links {	
        text-align: center;	
        width: 100%;	
        display: table;	
        justify-content: center;
      }	
    
      .navbar-logo {	
        position: absolute;	
      }	
    
      .menu-icon {	
        display: block;	
        position: absolute;	
        top: 0;	
        right: 0;	
        transform: translate(-100%, 30%);	
        font-size: 1.8rem;	
        cursor: pointer;	
        margin-top: 10px;
      }	

      .fa-bars{
        color: var(--color);	
      }
    
      .fa-times {	
        color: var(--color);	
        font-size: 2rem;	
      }	
    
      .nav-links-mobile {	
        display: block;	
        text-align: center;	
        margin: 2rem auto;	
        border-radius: 4px;	
        width: 80%;	
        text-decoration: none;	
        font-size: 1.5rem;	
        background-color: transparent;	
        color: var(--color);	
        padding: 14px 20px;	
        border: 1px solid var(--color);	
        transition: all 0.3s ease-out;	
      }	
}