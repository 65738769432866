.SlimCard{
    display: flex;
    padding: 0.5rem;
    .logo{
        flex: 0.2;
        padding-right: 15px;
        img{
            width: 100%;
        }
    }
    .info{
        flex: 0.8;
        margin-top: auto;
        margin-bottom: auto;
        color: var(--color);
        font-family: 'CherishToday';
        font-size: 26px;
        h2{
            font-size: 35px;
        }
    }
}

@media screen and (max-width: 960px){
    .SlimCard{
        .info{
            font-size: 16px;
            h2{
                font-size: 25px;
            }
        }
    }
    
}