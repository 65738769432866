@font-face {
    font-family: 'CherishToday';
    src: local('CherishToday'), url(../../fonts/CherishToday.ttf) format('truetype');
}

@font-face {
    font-family: 'SweetPurple';
    src: local('SweetPurple'), url(../../fonts/SweetPurple.ttf) format('truetype');
}

@font-face {
    font-family: 'CliffordEighteen';
    src: local('CliffordEighteen'), url(../../fonts/CliffordEighteen.ttf) format('truetype');
}

@font-face {
    font-family: 'Rattman';
    src: local('Rattman'), url(../../fonts/Rattman.ttf) format('truetype');
}

:root{
    --LightBackground: #F7F7F7;
    --LightText:#707070;
    --LightTextHover:#9e9e9e;
    --LightSwitch: #ffffff;
    --LightPanel: #E6E6E6;
    --StandingLight: url(../../images/LightStandingMe.svg);
    --LaptopLight: url(../../images/LightLaptop.svg);
    --LightOnLaptop: url(../../images/LightOnLaptop.svg);
    --LightSitting: url(../../images/LightSitting.svg);
    --MeStandingLight: url(../../images/LightMeLeaning.svg);
    --LightStanding: url(../../images/LightStanding.svg);
    --LightStandingHandPocket: url(../../images/LightStandingHandPocket.svg);

    --DarkBackground: #292929;
    --DarkText: #D6D6D6;
    --DarkTextHover: #b9b9b9;
    --DarkSwitch: #434343;
    --DarkPanel: #4B4B4B;
    --StandingDark: url(../../images/DarkStandingMe.svg);
    --LaptopDark: url(../../images/DarkLaptop.svg);
    --DarkOnLaptop: url(../../images/DarkOnLaptop.svg);
    --DarkSitting: url(../../images/DarkSitting.svg);
    --MeStandingDark: url(../../images/DarkMeLeaning.svg);
    --DarkStanding: url(../../images/DarkStanding.svg);
    --DarkStandingHandPocket: url(../../images/DarkStandingHandPocket.svg);
}

