@import '../shared/DefaultColours';

.sidebar{
    position: fixed;
    height: 100vh;
    z-index: 1;
    right: 0;
    margin-top: 40vh;
    margin-right: 10px;
}

.inline-icon{
    color: var(--color);
    font-size: 25px;
    padding: 20px;
    cursor: pointer;
}

.inline-icon:hover{
    color: var(--hover-color);
}

@media screen and (max-width: 960px) {
    .sidebar{
        visibility: hidden;
    }
}