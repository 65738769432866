.Projects{
    padding-top: 65px;
    .Header{
        h1{
            font-family: "SweetPurple";
            font-size: 70px;
            color: var(--color);
            margin: 20px;
            font-weight: 100;
            margin-bottom: 0;
            text-align: left;
        }
    }
}